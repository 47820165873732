<template>
  <div class="mt-5">
    <h1>{{ $t('registration.confirm.title') }}</h1>
    <p class="mt-3">{{ $t('registration.confirm.desc', { email }) }}</p>

    <v-form
      @submit.prevent="submit"
      v-model="isFormValid"
    >
      <v-row
        dense
      >
        <v-col cols="12" sm="6">
          <v-text-field
            class="centered code"
            v-model="code"
            v-mask="'#-#-#-#-#-#'"
            placeholder="#-#-#-#-#-#"
            @focus="value=''"
            :rules="codeRules"
            outlined
            validate-on-blur
            ref="code"
            @input="checkValidation('code')"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="resend"
    >
      <div
        class="text-decoration-underline primary--text"
        @click="resend"
      >
        {{ $t('forgotPassword.submitCode.resend') }}
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mask } from 'vue-the-mask';

export default {
  name: 'RegistrationConfirmation',
  directives: {
    mask,
  },
  data() {
    return {
      code: '',
      codeRules: [
        (v) => (v && v.split('-').join('').length === 6),
      ],
    };
  },
  computed: {
    ...mapFields('registration', [
      'isFormValid',
    ]),
    ...mapGetters('registration', [
      'email',
    ]),
  },
  methods: {
    async submit() {
      const code = this.code.split('-').join('');
      try {
        await this.$store.dispatch('registration/confirm', code);
        // await this.$store.dispatch('registration/confirmed');
        this.$notify.success(this.$t('registration.success'));
        this.$router.push({ name: 'SignIn' });
      } catch (error) {
        this.$notify.error(error);
      }
    },
    async resend() {
      try {
        await this.$store.dispatch('registration/resendCode');
        this.$notify.success(this.$t('registration.resend'));
      } catch (error) {
        this.$notify.error(error);
      }
    },
    checkValidation(refName) {
      if (!this.isFormValid) {
        this.$refs[refName].validate();
      }
    },
  },
};
</script>

<style scoped>
.centered >>> input {
  text-align: center;
  font-size: 2em;
  font-weight: 200;
  max-height: 42px !important;
  padding: 0 !important;
}

.resend {
  margin-left: 0.25rem;
  cursor: pointer;
}
</style>
